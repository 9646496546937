import {
    mergeStyles, updateA
} from '@fluentui/react';
import React, { useMemo } from 'react';
import { InnoLogoProps } from './InnostackLogo';

const createStyles = () => {
    // Base styles that don't need to be recreated
    const baseStyles = mergeStyles({
        zIndex: 9001
    });

    // Delay variants
    const delay0 = mergeStyles({
        animationDelay: '0ms, 2s'
    });

    const delay300 = mergeStyles({
        animationDelay: '300ms, 1.8s'
    });

    const delay600 = mergeStyles({
        animationDelay: '600ms, 1.6s'
    });

    return {
        base: baseStyles,
        delay0,
        delay300,
        delay600
    };
};

export const SvgLogo: React.FC<InnoLogoProps> = (({
    id,
    logoColor,
    animate,
    animateInfinite
}) => {
    const myStyle = useMemo(() => {
        return {
            fill: logoColor.str
        };
    }, [logoColor]);

    const styles = useMemo(() => { return createStyles(); }, []); // Create styles once

    const pathAnimation = useMemo(() => {
        const inf = animateInfinite ? ' infinite ' : ' ';

        return mergeStyles({
            opacity: 0,
            transform: 'translateY(-50px)',
            animation: `pathEntrance 1s cubic-bezier(0.4, 0, 0.2, 1) forwards, bloom 10s ease-in-out 2s${inf}forwards`,
            filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0))'
        });
    }, [animateInfinite]);


    const keyFrames = useMemo(() => {
        return `
        @keyframes pathEntrance {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes bloom {
            0% {
                filter: drop-shadow(0 0 8px ${updateA(logoColor, 0).str});
            }
            5% {
                filter: drop-shadow(0 0 12px ${updateA(logoColor, 50).str});
            }
            10% {
                filter: drop-shadow(0 0 8px ${updateA(logoColor, 0).str});
            }
        }
    `;
    }, [logoColor]);

    return useMemo(() => {
        return (
            <>
                <style>{keyFrames}</style>
                <g id={`${id}_icon`}>
                    {/* Bottom path */}
                    <path
                        d='M53.4 84 10.9 62.2 0 67.8l53.4 27.5 53.4-27.5-10.9-5.6z'
                        className={mergeStyles(
                            styles.base,
                            animate && pathAnimation,
                            styles.delay0,
                            myStyle
                        )}
                    />

                    {/* Middle path */}
                    <path
                        d='M53.4 65.1 10.9 43.4 0 49l53.4 27.4L106.8 49l-10.9-5.7z'
                        className={mergeStyles(
                            styles.base,
                            animate && pathAnimation,
                            styles.delay300,
                            myStyle
                        )}
                    />

                    {/* Top path */}
                    <path
                        d='M95.5 21.6 53.4 43.1 22.9 27.4l30.5-15.6 20.5 10.5 11.6-5.9L53.4 0 0 27.4l53.4 27.5 53.5-27.5 3-7.9z'
                        className={mergeStyles(
                            styles.base,
                            animate && pathAnimation,
                            styles.delay600,
                            myStyle
                        )}
                    />
                </g>
            </>
        );
    }, [animate, id, keyFrames, myStyle, pathAnimation, styles.base, styles.delay0, styles.delay300, styles.delay600]);
});
