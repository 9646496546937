import { MouseEventHandler } from 'react';
import {
    NavLink, useMatch, useResolvedPath
} from 'react-router-dom';

export interface ICustomNavLinkProps {
    to: string,
    children: (active: boolean)=> JSX.Element | JSX.Element[],
    onClick?: MouseEventHandler<HTMLAnchorElement>
}

const CustomNavLink: (props: ICustomNavLinkProps)=> JSX.Element = ({ to, children, onClick }) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({
        path: resolved.pathname,
        end: true
    });

    return (
        <NavLink to={to} onClick={onClick} style={{
            textDecoration: 'none',
            cursor: 'pointer',
            alignItems: 'center',
            display: 'flex'
        }}>
            {children(!!match)}
        </NavLink>
    );
};

export default CustomNavLink;
