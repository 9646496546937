import React, {
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    ActionButton,
    Stack,
    useTheme,
    Text,
    Icon,
    Panel,
    getColorFromString
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
    HEIGHT_PADDING_NAV_DESKTOP,
    HEIGHT_PADDING_NAV_MOBILE,
    HEIGHT_PADDING_NAV_TABLET,
    NAVBAR_HEIGHT,
    SIDE_PADDING_DESKTOP, SIDE_PADDING_MOBILE, SIDE_PADDING_TABLET, makeResponsive
} from '../consts';
import CustomNavLink from './CustomNavLink';
import { InnostackLogo } from './logo/InnostackLogo';

export interface INavigationProps {
    forceDocked?: boolean;
}


const NAVITEMS: {
    link: string,
    titleTK: string
}[] = [
    {
        link: '/',
        titleTK: 'NAV.LINK.HOME'
    },
    {
        link: '/imprint',
        titleTK: 'NAV.LINK.IMPRINT'
    }
];

const Navigation: React.VFC<INavigationProps> = (props) => {
    const { forceDocked } = props;

    const { t: translate } = useTranslation('common');
    const theme = useTheme();

    const [isScrolled, setIsScrolled] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.body.onscroll = (event) => {
            const scrollTop = document.scrollingElement?.scrollTop;

            setIsScrolled(scrollTop !== undefined && scrollTop >= 10);
        };
    }, []);

    const navitems = useMemo(() => {
        return NAVITEMS.map((item) => {
            const title = translate(item.titleTK);

            return <CustomNavLink to={item.link} key={item.link}>
                {(active) => {
                    return <Text styles={{
                        root: {
                            color: theme.palette.white,
                            fontSize: 'calc(1rem + .1vw)',
                            textDecoration: active ? 'underline' : 'unset'
                        }
                    }}>{title}</Text>;
                }}
            </CustomNavLink>;
        });
    }, [theme, translate]);

    const navitemsMobile = useMemo(() => {
        return NAVITEMS.map((item) => {
            const title = translate(item.titleTK);

            return <CustomNavLink to={item.link} key={item.link}
                onClick={(event) => {
                    setIsOpen(false); // close mobile nav when click on element
                }}
            >
                {(active) => {
                    return <Stack styles={{
                        root: {
                            width: '100%',
                            padding: '1rem',
                            '&:hover': {}
                        }
                    }}><Text styles={{
                            root: {
                                color: theme.palette.black,
                                fontSize: 'calc(1rem + .1vw)',
                                textDecoration: active ? 'underline' : 'unset'
                            }
                        }}>{title}</Text>
                    </Stack>;
                }}
            </CustomNavLink>;
        });
    }, [theme, translate]);

    return <Stack
        className='navigation'
        verticalAlign='center'
        horizontal
        tokens={{
            childrenGap: '1rem'
        }}
        styles={{
            root: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 9999,
                backgroundColor: (forceDocked || isScrolled) ? theme.palette.themeDarker : undefined,
                transition: 'background-color .3s ease-in-out',
                ...makeResponsive(
                    {
                        padding: `${HEIGHT_PADDING_NAV_MOBILE} 1rem ${HEIGHT_PADDING_NAV_MOBILE} ${SIDE_PADDING_MOBILE}`,
                        height: NAVBAR_HEIGHT
                    },
                    {
                        padding: `${HEIGHT_PADDING_NAV_TABLET} 1rem ${HEIGHT_PADDING_NAV_TABLET} ${SIDE_PADDING_TABLET}`
                    },
                    {
                        padding: `${HEIGHT_PADDING_NAV_DESKTOP} 1rem ${HEIGHT_PADDING_NAV_DESKTOP} ${SIDE_PADDING_DESKTOP}`
                    }
                )
            }
        }}>
        <nav style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
            alignItems: 'center'
        }}>
            <Panel
                isOpen={isOpen}
                onDismiss={() => {
                    setIsOpen(false);
                }}
                closeButtonAriaLabel={translate('NAV.CLOSE')}
                styles={{
                    root: {
                        ...makeResponsive(
                            {
                                display: 'flex'
                            },
                            {
                                display: 'none'
                            }
                        )
                    },
                    content: {
                        padding: 0
                    }
                }}
            >

                {navitemsMobile}
            </Panel>
            <Stack
                className='logo'
                horizontal
                verticalAlign='center'
                tokens={{
                    childrenGap: '1rem'
                }}
                styles={{
                    root: {
                        height: `100%`
                    }
                }}>
                <InnostackLogo id='Innostack_Logo' animate
                    logoColor={getColorFromString('#fff')!}
                    style={{
                        height: '100%',
                        overflow: 'visible'
                    }} />
            </Stack>
            <Stack
                className='navigation-items'
                horizontal
                grow
                styles={{
                    root: {
                        padding: '0 .5rem 0 1rem',
                        overflow: 'hidden',
                        height: '100%'
                    }
                }}>
                <Stack className='nav-left' horizontal grow verticalAlign='center' tokens={{
                    childrenGap: '1rem'
                }} styles={{
                    root: {
                        paddingLeft: '1rem',
                        ...makeResponsive(
                            {
                                display: 'none'
                            },
                            {
                                display: 'flex'
                            }
                        )
                    }
                }}>
                    {navitems}
                </Stack>
                <Stack className='nav-right' grow horizontal horizontalAlign='end' verticalAlign='center'>
                    <ActionButton aria-label='menu-button'>
                        <Icon iconName='GlobalNavButton' aria-label='menu-icon'
                            onClick={() => { setIsOpen(true); }}
                            styles={{
                                root: {
                                    color: theme.palette.white,
                                    fontSize: 'calc(1rem + .1vw)',
                                    ...makeResponsive(
                                        {
                                            display: 'flex'
                                        },
                                        {
                                            display: 'none'
                                        }
                                    )
                                }
                            }}/>
                    </ActionButton>
                </Stack>
            </Stack>
        </nav>
    </Stack>;
};

export default Navigation;
