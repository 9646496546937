import { IColor } from '@fluentui/react';
import React, { useMemo } from 'react';
import { SvgLogo } from './SvgLogo';
import { SvgText } from './SvgText';

export type InnoLogoProps = {
    id: string;
    // TODO get Color from Theme
    logoColor: IColor;
    animate?: boolean | undefined;
    animateInfinite?: boolean | undefined;
}

export const InnostackLogo: React.FC<React.SVGProps<SVGSVGElement> & InnoLogoProps> = ({
    id,
    logoColor,
    animate,
    animateInfinite,
    ...svgProps
}) => {
    const container = useMemo(() => {
        return (

            <g id={`${id}_container`}>
                <SvgText id={id} logoColor={logoColor} />
                <SvgLogo id={id} logoColor={logoColor} animate={animate} animateInfinite={animateInfinite} />
            </g>
        );
    }, [animate, animateInfinite, id, logoColor]);

    return useMemo(() => {
        return (
            <svg
                id={id}
                xmlns='http://www.w3.org/2000/svg'
                xmlSpace='preserve'
                x='0'
                y='0'
                version='1.1'
                viewBox='0 0 545 95.3'
                {...svgProps}
            >
                {container}
            </svg>
        );
    }, [id, svgProps, container]);
};
