import React, { useMemo } from 'react';
import {
    Outlet, useLocation
} from 'react-router-dom';
import { Stack } from '@fluentui/react';
import Navigation from './components/Navigation';

export const MainLayout: React.VFC = () => {
    const location = useLocation();
    const isDocked = location.pathname !== '/';

    return useMemo(() => {
        return (
            <Stack
                grow
                styles={{
                    root: {
                        width: '100%',
                        overflow: 'visible'
                    }
                }}
            >
                <Navigation
                    forceDocked={isDocked}
                />
                <Outlet />
            </Stack>
        );
    }, [isDocked]);
};
