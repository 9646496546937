
import React, { PropsWithChildren } from 'react';
import {
    Stack, useTheme
} from '@fluentui/react';

export interface IAppWrapperProps {
}

const AppWrapper: React.FunctionComponent<PropsWithChildren<IAppWrapperProps>> = (props) => {
    const { children } = props;
    const theme = useTheme();

    return <Stack
        className='app-wrapper'
        styles={{
            root: {
                minHeight: '100vh',
                backgroundColor: theme.palette.themeTertiary
            }
        }}>
        {children}
    </Stack>;
};

export default AppWrapper;
