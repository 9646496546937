import React from 'react';
import {
    Stack,
    Text
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import CompanyInfos from '../infos/CompanyInfos';
import HRBInfos from '../infos/HRBInfos';
import { IPageProps } from '../../App';
import HideFromBots, {
    HiddenLinkType, hideFromBotsOnClick
} from '../../components/HideFromBots';
import { NAVBAR_HEIGHT } from '../../consts';

const ImprintPage: React.VFC<IPageProps> = () => {
    const { t: translate } = useTranslation('common');
    const eu_odr = <a href='https://ec.europa.eu/consumers/odr/' target='_blank' rel='noopener noreferrer'>https://ec.europa.eu/consumers/odr/</a>;

    return <Stack className='imprint-page'
        grow
        styles={{
            root: {
                width: '100%',
                paddingTop: NAVBAR_HEIGHT
            }
        }}
    >
        <Stack
            className='imprint'
            verticalFill
            horizontalAlign='center'
            verticalAlign='center'
            styles={{
                root: {
                    padding: '2rem'
                }
            }}>
            <Text variant='xLargePlus'>{translate('IMPRINT.TITLE')}</Text>
            <Stack className='imprint-content'
                tokens={{
                    childrenGap: '1rem'
                }} styles={{
                    root: {
                        paddingTop: '1rem'
                    }
                }}>
                <Stack className='tmg'>
                    <Text variant='mediumPlus'>{translate('IMPRINT.LEGISALTION')}</Text>
                    <CompanyInfos/>
                </Stack>
                <Stack>
                    <HRBInfos/>
                </Stack>
                <Stack className='executives'>
                    <Text variant='mediumPlus'>{translate('IMPRINT.REPRESENTED')}</Text>
                    <ul>{translate('IMPRINT.EXECUTIVES').split(',')
                        .map((executive) => {
                            return <li key={executive}><Text>{executive}</Text></li>;
                        })}
                    </ul>
                </Stack>
                <Stack className='contact'>
                    <Text variant='mediumPlus'>{translate('IMPRINT.CONTACT')}</Text>
                    <Text>
                        {translate('IMPRINT.PHONE')}
                        <Text
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => { hideFromBotsOnClick(translate('INFOS.COMPANY_PHONE_CALLABLE_BASE64'), HiddenLinkType.Tel); }}>
                            <HideFromBots base64Data={translate('INFOS.COMPANY_PHONE_BASE64')}/>
                        </Text>
                    </Text>
                    <Text>
                        {translate('IMPRINT.MAIL')}
                        <Text
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => { hideFromBotsOnClick(translate('INFOS.ADMIN_MAIL_BASE64'), HiddenLinkType.Mail); }}>
                            <HideFromBots base64Data={translate('INFOS.ADMIN_MAIL_BASE64')}/>
                        </Text>
                    </Text>
                </Stack>
                <Stack className='vat'>
                    <Text variant='mediumPlus'>{translate('IMPRINT.TAX_ID')}</Text>
                    <Stack horizontal tokens={{
                        childrenGap: '.25rem'
                    }}>
                        <Text>{translate('IMPRINT.TAX_ID_LAW')}</Text>
                        <Text>{translate('IMPRINT.TAX_ID_NO')}</Text>
                    </Stack>
                </Stack>
                <Stack className='eu-dispute'>
                    <Text variant='mediumPlus'>{translate('IMPRINT.EU_DISPUTE')}</Text>
                    <Text>{translate('IMPRINT.EU_DISPUTE_L1')} {eu_odr}.</Text>
                    <Text>{translate('IMPRINT.EU_DISPUTE_L2')}</Text>
                </Stack>
                <Stack className='customer-dispute'>
                    <Text variant='mediumPlus'>{translate('IMPRINT.CLIENT_DISPUTE')}</Text>
                    <Text>{translate('IMPRINT.CLIENT_DISPUTE_L1')}</Text>
                </Stack>
            </Stack>
        </Stack>
    </Stack>;
};

export default ImprintPage;
