import {
    getColorFromString,
    Icon,
    Stack, Text, useTheme
} from '@fluentui/react';
import { TFunction } from 'i18next';
import React, {
    useCallback, useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Navigate, NavLink, useParams
} from 'react-router-dom';
import VCard from 'vcard-creator';
import { IPageProps } from '../../App';
import {
    b64_to_utf8,
    HiddenLinkType,
    hideFromBotsOnClick
} from '../../components/HideFromBots';
import { InnostackLogo } from '../../components/logo/InnostackLogo';
import { makeResponsive } from '../../consts';

export interface IContact {
    url: string,
    name: string,
    picture?: string,
    phoneBase64: string,
    mailBase64: string,
    vcardInfo: {
        firstname: string,
        lastname: string,
        birthday?: string,
        address?: {
            name: string,
            street: string,
            city: string,
            zipCode: string,
            country: string
        },
        socials: {
            url: string,
            type: string,
            user?: string
        }[]
    }
}

const contacts: IContact[] = [
    {
        url: 'timoditz',
        name: 'Timo Ditz',
        mailBase64: 'dGltb2RpdHolNDBpbm5vc3RhY2submV0',
        phoneBase64: 'MDA0OTE3MDQxMTg1MjQ=',
        picture: 'timoditz.png',
        vcardInfo: {
            firstname: 'Timo',
            lastname: 'Ditz',
            socials: []
        }
    },
    {
        url: 'devinmex',
        name: 'Devin Mex',
        mailBase64: 'ZGV2aW5tZXglNDBpbm5vc3RhY2submV0',
        phoneBase64: 'MDA0OTE3NjIwNzM3NDE4',
        picture: 'devinmex.png',
        vcardInfo: {
            firstname: 'Devin',
            lastname: 'Mex',
            socials: []
        }
    },
    {
        url: 'dominicthiele',
        name: 'Dominic Thiele',
        mailBase64: 'ZG9taW5pY3RoaWVsZUBpbm5vc3RhY2submV0',
        phoneBase64: 'KzQ5MTUxNTMzNzE1OTM=',
        picture: 'dominicthiele.png',
        vcardInfo: {
            firstname: 'Dominic',
            lastname: 'Thiele',
            socials: []
        }
    }
];

const generateVCard = (contact: IContact, translate: TFunction) => {
    const card = new VCard();
    const info = contact.vcardInfo;

    if (info.address) {
        card.addAddress(info.address.name,
            undefined,
            info.address.street,
            info.address.city,
            undefined,
            info.address.zipCode,
            info.address.country
        );
    } else {
        card.addAddress('Innostack GmbH',
            undefined,
            'Viktoriastr. 24',
            'Dortmund',
            undefined,
            '44135',
            'Germany'
        );
    }

    info.birthday && card.addBirthday(info.birthday);
    card.addNickname(info.firstname);
    card.addName(info.lastname, info.firstname);
    card.addCompany(translate('APP.COMPANY_NAME'));
    card.addEmail(b64_to_utf8(contact.mailBase64));
    card.addPhoneNumber(b64_to_utf8(contact.phoneBase64));
    card.addJobtitle(translate(`VCARD.CONTACTS.${contact.url.toUpperCase()}.POSITION`));
    card.addRole(translate(`VCARD.CONTACTS.${contact.url.toUpperCase()}.POSITION`));
    card.addPhotoURL(`${translate('APP.URL')}/assets/profile-pictures/${contact.picture}`);
    card.addLogoURL(`${translate('APP.URL')}/assets/fav.png`);
    card.addURL(translate('APP.URL'));

    info.socials.forEach((social) => {
        card.addSocial(social.url, social.type, social.user);
    });

    return card.toString();
};

const VCardPage: React.VFC<IPageProps> = () => {
    const theme = useTheme();
    const { t: translate } = useTranslation('common');
    const { name } = useParams();

    const contact = useMemo(() => {
        return contacts.find((contact) => { return contact.url === name?.replaceAll('-', '').replaceAll('_', ''); });
    }, [name]);

    const downloadVCard = useCallback((c: IContact) => {
        const element = document.createElement('a');

        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(generateVCard(c, translate))}`);
        element.setAttribute('download', `${c.name.replaceAll(' ', '_')}_${translate('APP.TRADEMARK_NAME').replaceAll(' ', '_')}.vcf`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }, [translate]);

    const renderInfoIcon = useCallback((key: string, href: string, iconName: string, title: string, target?: string, rel?: string) => {
        return <Stack key={key} className={`info-icon-${key}`}
            horizontalAlign='center'
            verticalAlign='center'
            styles={{
                root: {
                    padding: '.5rem',
                    height: '3rem',
                    width: '3rem',
                    backgroundColor: '#192642',
                    borderRadius: '50%'
                }
            }}
        >
            <a href={href} target={target} rel={rel} style={{
                height: '1.2rem',
                width: '1.2rem'
            }}>
                <Icon iconName={iconName} title={title}
                    styles={{
                        root: {
                            fontSize: '1.2rem',
                            color: theme.palette.white
                        }
                    }}
                />
            </a>
        </Stack>;
    }, [theme.palette.white]);

    const renderBase64InfoIcon = useCallback((key: string, iconName: string, title: string, base64Data: string, type: HiddenLinkType) => {
        return <Stack key={key} className={`info-icon-${key}`}
            horizontalAlign='center'
            verticalAlign='center'
            styles={{
                root: {
                    padding: '.5rem',
                    height: '3rem',
                    width: '3rem',
                    backgroundColor: '#192642',
                    borderRadius: '50%'
                }
            }}
        >
            <div onClick={() => { hideFromBotsOnClick(base64Data, type); }} style={{
                height: '1.2rem',
                width: '1.2rem',
                cursor: 'pointer'
            }}>
                <Icon iconName={iconName} title={title}
                    styles={{
                        root: {
                            fontSize: '1.2rem',
                            color: theme.palette.white
                        }
                    }}
                />
            </div>
        </Stack>;
    }, [theme.palette.white]);

    if (!contact) {
        return <Navigate to={'/'} />;
    }

    return <Stack className='vcard-page'
        verticalAlign='start'
        horizontalAlign='center'
        grow
        styles={{
            root: {
                width: '100%',
                height: '100%',
                backgroundColor: '#1d70b7',
                padding: '4rem 1rem 1rem 1rem'
            }
        }}
    >
        <Stack wrap className='vcard'
            style={{
                width: '100%',
                maxWidth: '600px',
                borderRadius: '.5rem',
                overflow: 'hidden',
                backgroundColor: '#192642'
            }}>
            <Stack className='vcard_infos' styles={{
                root: {
                    position: 'relative',
                    width: '100%'
                }
            }}>
                <img src='/assets/vcards/bg.jpg' alt={translate('VCARD.BG_ALT')} style={{
                    zIndex: 0,
                    objectFit: 'cover',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%'
                }} />


                <Stack className='logo' styles={{
                    root: {
                        backgroundColor: '#37a7df',
                        zIndex: 2,
                        padding: '2rem',
                        ...makeResponsive({
                            height: '8rem',
                            alignItems: 'center'
                        }, {
                            height: '5rem',
                            alignItems: 'start',
                            justifyContent: 'center'
                        })
                    }
                }}>
                    <NavLink to={'/'} style={{
                        display: 'contents',
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}>
                        <InnostackLogo id='Innostack_Logo' animate animateInfinite
                            logoColor={getColorFromString('#fff')!}
                            style={{
                                maxWidth: '10rem',
                                overflow: 'visible'
                            }} />
                    </NavLink>
                </Stack>
                <Stack className='profile-picture' horizontalAlign='center' styles={{
                    root: {
                        height: '6rem',
                        backgroundColor: '#1d70b7a0',
                        zIndex: 10
                    }
                }}>
                    <Stack className='person-img-wrapper'
                        horizontalAlign='center'
                        verticalAlign='center'
                        styles={{
                            root: {
                                height: '6rem',
                                width: '6rem',
                                border: '3px solid',
                                borderRadius: '50%',
                                borderColor: '#1d70b7',
                                marginTop: '-3rem',
                                zIndex: 10
                            }
                        }}>
                        {contact.picture
                            ? <img className='person-img' src={`/assets/profile-pictures/${contact.picture}`} alt={`${translate('VCARD.CONTACT_PIC_ALT')} ${contact.name}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '50%'
                                }} />
                            : <Icon iconName='Mail' styles={{
                                root: {
                                    fontSize: '2rem',
                                    color: theme.palette.white
                                }
                            }} />}
                    </Stack>
                </Stack>
                <Stack className='vcard-content' styles={{
                    root: {
                        backgroundColor: '#1d70b7a0',
                        zIndex: 10
                    }
                }}>
                    <Stack className='vcard-data' verticalAlign='center' horizontalAlign='center' styles={{}}>
                        <Text variant='xLargePlus' className='name'
                            style={{
                                color: theme.palette.white
                            }}>
                            {contact.name}
                        </Text>
                        <Text className='position'
                            variant='large'
                            style={{
                                color: theme.palette.white
                            }}>
                            {translate(`VCARD.CONTACTS.${contact.url.toUpperCase()}.POSITION`)}
                        </Text>
                    </Stack>
                    <Stack className='info-icons'
                        horizontal
                        horizontalAlign='center'
                        tokens={{
                            childrenGap: '.5rem',
                            padding: '3rem 1rem'
                        }}>
                        {renderBase64InfoIcon('phone', 'Phone', translate('VCARD.CALL'), contact.phoneBase64, HiddenLinkType.Tel)}
                        {renderBase64InfoIcon('mail', 'Mail', translate('VCARD.MAIL'), contact.mailBase64, HiddenLinkType.Mail)}
                        {renderInfoIcon('map', `https://www.google.com/maps/place/Innostack+GmbH/@51.1758057,10.4541194,6z/data=!4m6!3m5!1s0x8bd9aa4aba4bf9ff:0x247a7e2571bbed81!8m2!3d51.5134142!4d7.4702223!16s%2Fg%2F11t__xm13s?entry=ttu`, 'Nav2DMapView', translate('VCARD.MAP_VIEW'), '_blank', 'noreferrer')}
                        {renderInfoIcon('website', `https://www.innostack.net/`, 'Website', translate('VCARD.WEBSITE'), '_blank', 'noreferrer')}
                    </Stack>
                </Stack>
                <Stack className='vcard_save'
                    horizontal
                    horizontalAlign='center'
                    verticalAlign='center'
                    onClick={() => { downloadVCard(contact); }}
                    styles={{
                        root: {
                            color: theme.palette.white,
                            backgroundColor: '#192642',
                            padding: '1rem',
                            zIndex: 10,
                            cursor: 'pointer'
                        }
                    }}
                    tokens={{
                        childrenGap: '1rem'
                    }}>
                    <Icon iconName='ContactCard'
                        styles={{
                            root: {
                                fontSize: '1.2rem'
                            }
                        }}
                    />
                    <Text variant='mediumPlus' styles={{
                        root: {
                            color: theme.palette.white,
                            textTransform: 'uppercase',
                            paddingBottom: '0.15rem'
                        }
                    }}>{translate('VCARD.SAVE')}</Text>
                </Stack>
            </Stack>
        </Stack>
    </Stack>;
};

export default VCardPage;
